import React from 'react';
import { getConfig } from '../config';

function HealthCheck() {
    const config = getConfig();

    return (<div>
        Date: {new Date().toString()}
        <br />
        Version: 1.7.1.0
        <br />
        Environment: {config.ENV}
    </div>);
}

export default HealthCheck;