import React, {useContext, useState, useEffect} from 'react';

import Icon from '../Icon/Icon';
import { StationContext } from '../../contexts/StationContext';
import { LocationContext } from '../../contexts/LocationContext';
import { ProfileContext } from '../../contexts/ProfileContext';
import { FlifoContext } from '../../contexts/FlifoContext';
import { processLocations } from '../../utils/utilities';

const LocationOverlay = (props) => {
    const { stationProfile } = useContext(StationContext);
    const { flifo, updateAircraftMove } = useContext(FlifoContext);
    const { locationOverlayActive, UpdateLocation, selectedLocUpdateFlight } = useContext(LocationContext);   
    const { profile } = useContext(ProfileContext);
     
    const [selectedWorkAreaType, setSelectedWorkAreaType] = useState("");
    const [selectedWorkArea, setSelectedWorkArea] = useState("");
    const [selectedWorkLoc, setSelectedWorkLoc] = useState("");
    const [workLocCount, setWorkLocCount] = useState(0);
    const handleWorkAreaTypeClick = (workAreaType) => {
        setSelectedWorkAreaType(workAreaType);
    }
    const handleWorkAreaClick = (workArea) => {
        setSelectedWorkArea(workArea);
        let locations = stationProfile.locations.filter(l => l.workAreaName === workArea && l.workAreaTypeName === selectedWorkAreaType);
        setWorkLocCount(locations !== null ? locations.length : 0);
    }
    const handleWorkAreaLocClick = (workAreaLoc) => {
        setSelectedWorkLoc(workAreaLoc);       
    }
    const handleSaveWorkLocClick = () => {
        let location = (workLocCount > 0 && selectedWorkLoc !== "") ? selectedWorkLoc : (workLocCount === 0 && selectedWorkArea !== "") ? selectedWorkArea : "";
        if(location !== ""){
            const newlocation = stationProfile.locations.find(l => l.locationName === location);
            UpdateLocation(selectedLocUpdateFlight, newlocation, profile);
            updateAircraftMove(selectedLocUpdateFlight.tailNumber, newlocation);
        }
    }    

    useEffect(() => {
        setSelectedWorkAreaType("");
        setSelectedWorkArea("");
        setSelectedWorkLoc("");
    },[locationOverlayActive])

    const currentflifo = processLocations(flifo);
    return (
        <div className="location-overlay">
            <div className="location-header">
                <Icon variant='aircraft-icon' primaryColor="#ffffff" className="icon icon-aircraft" width='30px' height='30px' />
                <span className="tailnumber">{selectedLocUpdateFlight !== null && selectedLocUpdateFlight !== undefined ? selectedLocUpdateFlight.tailNumber : ""}</span>
                {/* <span>Update Current Location</span> */}
                <span style={{marginLeft: '10px'}}>Moving from: <span className="tailnumber">{selectedLocUpdateFlight.tows !== null ? selectedLocUpdateFlight.tows[0].towTo : selectedLocUpdateFlight.arrivalGate}</span></span>
            </div>
            <div className="location-container">
                <div className="location-section">
                    <div className="location-section-title">Work Area Type</div>                                    
                    <div className="location-items">
                        {
                            stationProfile.workAreaTypes.map(wat => {
                                return <div key={wat} className={selectedWorkAreaType === wat ?"location-item active" :"location-item"} onClick={handleWorkAreaTypeClick.bind(this,wat)}>
                                    {wat}
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className="location-section">
                    <div className="location-section-title">Work Area</div>
                    <div className="location-items">
                        {
                            stationProfile.workAreas.filter(wa => wa.workAreaTypeName === selectedWorkAreaType).map(wan => {
                                return <div key={wan.workAreaName} className={selectedWorkArea === wan.workAreaName ?"location-item active" :"location-item"} onClick={handleWorkAreaClick.bind(this,wan.workAreaName)}>
                                            {wan.workAreaName}
                                        </div>
                            })
                        }
                    </div>
                </div>
                <div className="location-section">
                    <div className="location-section-title">Location</div>
                    <div className="location-items">
                        {
                            stationProfile.locations.filter(l => l.workAreaName === selectedWorkArea && l.workAreaTypeName === selectedWorkAreaType).map(loc => {
                                let occupiedac = null;
                                if (currentflifo && currentflifo.length > 0) {
                                    console.log('loc curr flifo', currentflifo)
                                    const flightinfo = currentflifo.find(c => c.location.locationName === loc.locationName);
                                    if (flightinfo) occupiedac = flightinfo.flifo.tailNumber;
                                } 
                                return (
                                    <>
                                        <div key={loc.locationName} className={selectedWorkLoc === loc.locationName ? "location-item active" :"location-item"} onClick={handleWorkAreaLocClick.bind(this,loc.locationName)}>
                                            {loc.locationName}
                                        </div>
                                        { occupiedac ? <div className='current-occupied-ac'>{occupiedac}</div> : null }
                                    </>
                                )
                            })
                        }                        
                    </div>
                </div>
            </div>
            <div className="location-footer">
                <div className="footer-info">
                    <div>Location Selected:</div>
                    <div className="footer-loc-info">
                        {selectedWorkAreaType} {selectedWorkArea !== "" ?"/ " + selectedWorkArea:""} {selectedWorkLoc !== "" ?"/ " + selectedWorkLoc:""}                        
                    </div>
                </div>               
                <div id="cancel" className="action-btn cancel" onClick={props.HandleOverlayClick}> Cancel</div>
                <div className="action-btn save" onClick={handleSaveWorkLocClick}> Save</div>               
            </div>
        </div>
    )
}

export default LocationOverlay;