import config from './qa';

const CONFIG = {
    ...config, 
    ENV: 'stg',
    URL_AMT_API_BASE: 'https://terminalmaps-stg.ual.com/api',
    URL_AMT_BASE: 'https://amts-oqa.ual.com',
    AMT_GRAPHQL_ENDPT: '/graphql/',
    AMT_API_ENDPTS: {
    SAVE_AIRCRAFT_LOC: '/station/saveaircraftlocation',
    GET_USER_INFO: '/station/getuserinformation'
  },
    URL_OOS: 'https://techops-oqa.ual.com/MobileResponsiveReport/',
    DATA_DOG_APPLICATION_ID: '516ff33d-6ffd-4e13-b0fc-29aae99e200f',
    DATA_DOG_CLIENT_TOKEN: 'pubb5309096817fb2fd02ecbbab4d35d8ac'
};

export default CONFIG;