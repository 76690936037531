import React from 'react';


const SlideoutLocationSection = (props) => {
    return (
        <div key={props.workAreaType}>
            <div className="workarea-header" onClick={props.handleLocationTypeClick.bind(this,props.locationType)}>
                {props.locationType}
            </div>
            <div className={props.locationType === props.selectedLocationType? "workarea-list expanded":"workarea-list collapse"}>
                {props.locationItems.map(li => {
                    const locationItemDisplay = props.locationType === "Station" ? li.stationCode : li.workAreaName;
                    return <div key={locationItemDisplay} className={props.selectedLocationItem === locationItemDisplay ? "workarea-item selected":"workarea-item"} onClick={props.handleLocationItemClick.bind(this,li,locationItemDisplay,props.locationType)}>
                    {locationItemDisplay} 
                    </div>                                   
                })}
            </div>  
        </div>
    )
}

export default SlideoutLocationSection