import React from 'react';

const Spinner = (props) => {
      const spinnerPosition = props.position !== undefined && (props.position === 'center' || props.position === 'modal' ) ? 'spinner-position-center' : 'spinner-position';
      const spinnerContainer = props.position !== undefined ? (props.position === 'center' ? 'spinner-container-disabled' : props.position === 'modal' ? 'spinner-container-modal' : 'spinner-container') : 'spinner-container';
      return (
      <div id="spinnerOverlay">
         <div className={spinnerContainer}></div>
         <div className={spinnerPosition} role="progressbar">
            <div className="spinner-position-zero">
                  <div className="spinner-rotate-zero">
                  </div>
            </div>
            <div className="spinner-position-one">
                  <div className="spinner-rotate-one">
                  </div>
            </div>
            <div className="spinner-position-two">
                  <div className="spinner-rotate-two">
                  </div>
            </div>
            <div className="spinner-position-three">
                  <div className="spinner-rotate-three">
                  </div>
            </div>
            <div className="spinner-position-four">
                  <div className="spinner-rotate-four">
                  </div>
            </div>
            <div className="spinner-position-five">
                  <div className="spinner-rotate-five">
                  </div>
            </div>
            <div className="spinner-position-six">
                  <div className="spinner-rotate-six">
                  </div>
            </div>
            <div className="spinner-position-seven">
                  <div className="spinner-rotate-seven">
                  </div>
            </div>
         </div>
      </div>
   )
}

export default Spinner;