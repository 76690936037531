import React from 'react';
import Icon from '../Icon/Icon'
import OOSLegend from '../../assets/images/OOS-legend.svg';
import PulseMarker from '../../assets/images/pulse-marker.svg';
import PulseMarkerMini from '../../assets/images/pulse-marker-mini.svg';

const SlideoutLegend = () => {
    return(
        <div className="legend-container">
            <div className="legend-sections">
                <div className="legend-section">
                    <div className="legend-subheader">
                        MRD Status
                    </div>
                    <div className="legend-item">
                        <div className="legend-icon"><Icon variant='broken-mrd-icon' primaryColor='#CD202C' height='40px' width='50px' className="icon legend-svg mrd-icon" /></div>
                        <div className="legend-desc">Broken MRD</div>                    
                    </div>
                    <div className="legend-item">
                        <div className="legend-icon"><Icon variant='mrd-icon' primaryColor='#7AC149' height='16px' width='49px' className="icon legend-svg" /></div>
                        <div className="legend-desc">Issued MRD</div>                    
                    </div>
                </div>
                <div className="legend-section">
                    <div className="legend-subheader">
                        Aircraft Status
                    </div>
                    <div className="legend-item">
                        <div className="legend-icon"><Icon variant='aircraft-icon' primaryColor='#002244' height='40px' width='40px' className="icon legend-svg" /></div>
                        <div className="legend-desc">Aircraft at gate</div>                    
                    </div>
                    <div className="legend-item">
                        <div className="legend-icon"><Icon variant='star-icon' primaryColor='#002244' height='29px' width='29px' className="icon legend-svg" /></div>
                        <div className="legend-desc">Star Departure Aircraft at gate</div>                    
                    </div>
                    <div className="legend-item">
                        <div className="legend-icon"><Icon variant='etops-icon' primaryColor='#002244' height='40px' width='40px' className="icon legend-svg" /></div>
                        <div className="legend-desc">ETOPS</div>                    
                    </div>
                    <div className="legend-item">
                        <div className="legend-icon"><Icon variant='ron-icon' primaryColor='#002244' height='22.3px' width='23.3px' className="icon legend-svg"  /></div>
                        <div className="legend-desc">RON flight</div>                    
                    </div>
                    <div className="legend-item">
                        <div className="legend-icon legend-icon-big"><OOSLegend/></div>
                        <div className="legend-desc">Out of service</div>                    
                    </div>
                    <div className="legend-item">
                        <div className="legend-icon"><PulseMarker/></div>
                        <div className="">Aircraft at Gate, 15 min to departure and broken MRD</div>                    
                    </div>
                    <div className="legend-item">
                        <div className="legend-icon"><Icon variant='parked-aircraft-icon' primaryColor='#002244' height='40px' width='30px' className="icon legend-svg"  /></div>
                        <div className="legend-desc">Stored Aircraft</div>                    
                    </div>
                    <div className="legend-item">
                        <div className="legend-icon"><Icon variant='unrouted-aircraft-icon' primaryColor='#002244' height='40px' width='40px' className="icon legend-svg"  /></div>
                        <div className="legend-desc">Unrouted Aircraft</div>                    
                    </div>
                    <div className="legend-item">
                        <div className="gate-ind marker-gate apu-on gate-long-name">Gate #</div>
                        <div className="legend-desc">APU on </div>                   
                    </div>
                    <div className="legend-item">
                        <div className="priority-ind">P</div>
                        <div className="legend-desc">Priority flight</div>                    
                    </div>
                </div>
                <div className="legend-section">
                    <div className="legend-subheader">
                        Workload Status
                    </div>
                    <div className="legend-item">
                        <div className="legend-icon">
                            <div className="open-workload">
                                <Icon variant='wrench-icon' primaryColor='#CD202C' height='26px' width='26px' className="icon legend-svg" /></div>
                            </div>
                        <div className="legend-desc">Open Workload</div>                    
                    </div>
                    <div className="legend-item">
                        <div className="legend-icon">
                            <div className="complete-workload">
                                <Icon variant='wrench-icon' primaryColor='#002244' height='26px' width='26px' className="icon legend-svg" /></div>
                            </div>
                        <div className="legend-desc">Completed Workload</div>                    
                    </div>
                    <div className="legend-item">
                        <div className="legend-icon">
                            <div className="no-workload">
                                <Icon variant='wrench-icon' primaryColor='#7C848A' height='26px' width='26px' className="icon legend-svg" /></div>
                            </div>
                        <div className="legend-desc">No Workload</div>                    
                    </div>                
                </div>
                <div className="legend-section">
                    <div className="legend-subheader">
                        Status at zoomed out view
                    </div>
                    <div className="legend-item">
                        <div className="legend-icon">
                            <div className="aircraft-at-gate"></div>
                        </div>
                        <div className="legend-desc">Aircraft at Gate</div>                    
                    </div>
                    <div className="legend-item">
                        <div className="legend-icon">
                            <div className="aircraft-broken-mrd15"></div>
                        </div>
                        <div className="legend-desc">OOS Aircraft at Gate</div>                    
                    </div>
                    <div className="legend-item">
                        <div className="legend-icon">
                            <div className="aircraft-unrouted"></div>
                        </div>
                        <div className="legend-desc">Stored Aircraft</div>                    
                    </div>
                    <div className="legend-item">
                        <div className="legend-icon">
                            <div className="aircraft-parked"></div>
                        </div>
                        <div className="legend-desc">Unrouted Aircraft</div>                    
                    </div>
                    <div className="legend-item">
                        <div className="legend-icon">
                            <PulseMarkerMini />
                        </div>
                        <div className="">Aircraft at Gate, 15 min to departure and broken MRD</div>                    
                    </div>                
                </div>            
            </div>
        </div>
    )
}

export default SlideoutLegend;