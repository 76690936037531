import React from "react";
import Iframe from 'react-iframe';
import { getConfig } from '../../config';

const  SlideoutOOS = (props) => {
   const config = getConfig();
   return( props.station!=='HSC' || props.station!== 'OFF'? <Iframe url={config.URL_OOS+"HMCRegular?station="+props.station} className = "slideout-content-oos"/>:
      <Iframe url={config.URL_OOS} className = "slideout-content-oos"/>
   )
}

export default SlideoutOOS;