import React, {createContext, useState} from 'react';
import { GET_APU_INFO } from '../gql/queries';
import axios from 'axios';
import { getConfig } from '../config';

export const WorkloadContext = createContext();
const WorkloadContextProvider = (props) => {
    const [apuInformation, setApuInformation] = useState([]);
    const [workload, setWorkload] = useState([]); 
    const [loading, setLoading] = useState(false);
    const config = getConfig();
    const axiosGraphQL = axios.create({
        baseURL: `${config.URL_AMT_API_BASE}${config.AMT_GRAPHQL_ENDPT}`
    });  
    const getApuInformation = async (tailNumber, station) => {
        setApuInformation([]);
        const svcconfig = { headers: {'Pragma': 'no-cache',  'Content-Type': 'application/json'} };
        const variables = {"tailNumber": tailNumber, "station": station};
        const response = await axiosGraphQL
                            .post('',{query: GET_APU_INFO,"variables": variables}, svcconfig)
                            .then(resp => {
                            if (resp.statusText !== "OK" && resp.statusText !== "") throw new Error('Failed to get data');
                            
                            return resp.data.data.flightInformation.apuInformations;
        })
        .catch((err) => {
            console.log(err);
            window.location.href = "/api/Auth/Load";
        });

        setApuInformation(response);
    }
    const getWorkload = async (tailNumber, flightNumber, nextFlightNumber, arrivalCity, scheduleArrivalLocal, estimateArrivalLocal, isRon, nextScheduleDepartureLocal, nextEstimateDepartureLocal) => { 
        setLoading(true);
        // build workload query
        const formattedSchedArrivalLocal = scheduleArrivalLocal === null ? null : `"${scheduleArrivalLocal}"`;
        const formattedEstimArrivalLocal = estimateArrivalLocal === null ? null : `"${estimateArrivalLocal}"`;
        const formattedNextSchedDepartureLocal = nextScheduleDepartureLocal === null ? null : `"${nextScheduleDepartureLocal}"`;
        const formattedNextEstimDepartureLocal = nextEstimateDepartureLocal === null ? null : `"${nextEstimateDepartureLocal}"`;

        const workloadQuery = `
        {
            workload(tailNumber: "${tailNumber}", flightNumber: "${flightNumber}", nextFlightNumber: "${nextFlightNumber}", arrivalCity: "${arrivalCity}", scheduleArrivalLocal: ${formattedSchedArrivalLocal}, estimateArrivalLocal: ${formattedEstimArrivalLocal}, isRon: ${isRon}, nextScheduleDepartureLocal: ${formattedNextSchedDepartureLocal}, nextEstimateDepartureLocal: ${formattedNextEstimDepartureLocal}) {
                tailNumber, 
                hasWorkload, 
                workloadItems {
                    maintenanceItemFormatted,
                    maintenanceItem,
                    maintenanceDescription,
                    jobName,
                    maintenanceType,
                    positionCode,
                    isCompleted, 
                    subStation
                }
            },
            logpages(tailNumber: "${tailNumber}", statuses: ["O", "D", "C"]) {
                maintenanceItemFormatted,
                maintenanceItem,
                maintenanceDescription,
                discrepancyStatusCode,
                discrepancyStatusAndType,
                lastUpdated
            }
        }`;
        // call endpoint for workload
        var workload = await axiosGraphQL
                            .post('',{query: workloadQuery})
                            .then(response => {
                            if (response.statusText !== "OK" && response.statusText !== "") throw new Error('Failed to get data');
                            
                            return response.data.data;
        })
        .catch((err) => {
            console.log(err);
            window.location.href = "/api/Auth/Load";
        });
        setWorkload(workload);
        setLoading(false);   
    }

    return(
        <WorkloadContext.Provider value={{workload, loading, apuInformation, getWorkload, setWorkload, setLoading, getApuInformation, setApuInformation}}>
            {props.children}
        </WorkloadContext.Provider>
    )
}
export default WorkloadContextProvider;