export const GET_APU_INFO = `
    query FlightInfo($tailNumber: String, $station: String) {
        flightInformation {
            apuInformations(tailNumber: $tailNumber, station: $station) {
                isApuRunning
                isEngineRunning
                openDate
                apuRuntime
                apuCost
                activityDate
            }
        }
    }
`;