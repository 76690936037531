import config from './production';

const CONFIG = {
    ...config, 
    ENV: 'production', 
    URL_AMT_API_BASE: 'https://terminalmaps.bzq.aws.ual.com/api',
    URL_AMT_BASE: 'https://amts.ual.com', 
    DISABLE_AC_MOVE: true,
    URL_OOS: 'https://techops.ual.com/MobileResponsiveReport/'
};

export default CONFIG;