import React, {useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { getConfig } from '../../config';

import { StationContext } from '../../contexts/StationContext';
import { MapContext } from '../Map/MapContext';
import { WorkloadContext } from '../../contexts/WorkloadContext';
import {LocationContext} from '../../contexts/LocationContext';
import Icon from '../Icon/Icon';
import Spinner from '../Spinner/Spinner';
import ArrIcon from '../../assets/images/icon-arrival-white.svg';
import DepIcon from '../../assets/images/icon-departure-white.svg';
import BrokenMRDIcon from '../../assets/images/icon-mrd.svg';
import GreenWrench from '../../assets/images/wrench-green.svg';
import GreyWrench from '../../assets/images/wrench-grey.svg';
import RedWrench from '../../assets/images/wrench-red.svg';
import ApprovedMfd from '../../assets/images/icon-mfd-approved.svg';
import ArrangedMfd from '../../assets/images/icon-mfd-arranged.svg';
import IssuedMfd from '../../assets/images/icon-mfd-issued.svg';

const Workload = () => {
    const {stationProfile} = useContext(StationContext);
    const {selectedFlight, setSelectedFlight} = useContext(MapContext);
    const {workload, loading, apuInformation, setWorkload, setApuInformation} = useContext(WorkloadContext);
    const {UpdateLocationOverlay, updateSelectedLocpUpdateFlight} = useContext(LocationContext);
    const config = getConfig();
    
    const [offset, setOffset] = useState(null);
	useEffect(() => {
		if (stationProfile && stationProfile.stations && stationProfile.currentStation) {
			const stationOffset = stationProfile.stations.find(s => s.stationCode === stationProfile.currentStation) ? stationProfile.stations.find(s => s.stationCode === stationProfile.currentStation).gmtOffset : null;

			stationOffset ? setOffset(stationOffset) : setOffset(null);
		}
	},[stationProfile])

    const handleCloseWorkload= () => {
        setApuInformation([]);
        setSelectedFlight(null);
        setWorkload([]);
    }

    const handleLocationClick = (flight) => {
        UpdateLocationOverlay(true);
        updateSelectedLocpUpdateFlight(flight);
        handleCloseWorkload();
    }

    const myWork = selectedFlight !== null ?
        'tail='+selectedFlight.tailNumber+'&station='+selectedFlight.arrivalCity+'&inFlightNumber='+selectedFlight.flightNumber
        +'&outFlightNumber='+selectedFlight.nextFlightNumber+'&arrivalDate='+selectedFlight.scheduleArrivalLocal
        +'&nextDeparture='+selectedFlight.nextScheduleDepartureLocal+'&packageType='+(selectedFlight.isRon ? 'R' : 'T') 
        +'&fleet='+selectedFlight.fleet+'&estimatedArrival='+selectedFlight.estimateArrivalLocal+'&nextScheduledDeparture='
        +selectedFlight.nextScheduleDepartureLocal+'&nextEstimatedDeparture='+selectedFlight.nextEstimateDepartureLocal
        +'&isRon='+selectedFlight.isRon : "";

    const validWorkload = [];
    if (workload.workload !== null && workload.workload !== undefined && workload.workload.length > 0 && workload.workload[0].workloadItems !== null && workload.workload[0].workloadItems.length > 0) {
        // add dash 8 infomation to valid workload
        const schedd8 = workload.workload[0].workloadItems.filter(wi => wi.maintenanceType.toUpperCase() === "D8");
        schedd8.forEach(d => {
            const duplicate = validWorkload.find(vw => vw.maintenanceItem.trim() === d.maintenanceItem.trim());
            if (duplicate === undefined) validWorkload.push(d);
        });

        // add scheuled deferred log pages 
        const deferred = workload.logpages.filter(lp => lp.discrepancyStatusCode === "D");
        const schedlp = workload.workload[0].workloadItems.filter(wi => wi.maintenanceType.toUpperCase() === "LP" && !wi.isCompleted);
        schedlp.forEach(l => {
            const pair = deferred.find(lp => lp.maintenanceItem.trim() === l.maintenanceItem.trim());
            if (pair !== undefined) {
                pair.isCompleted = l.isCompleted;
                if (l.subStation) pair.subStation = l.subStation;
                validWorkload.push(pair);
            }
        })
    }

    const closedWorkload = [];
    if (workload && workload.logpages) {
        const cleared = workload.logpages.filter(lp => lp.discrepancyStatusCode === "C" && lp.lastUpdated !== null && moment(moment(lp.lastUpdated).format('MM/DD/YYYY')).isSameOrAfter(moment().format('MM/DD/YYYY')));
        cleared.forEach(clp => {
            let workloadlp = null;
            if (workload.workload && workload.workload[0].workloadItems) workloadlp = workload.workload[0].workloadItems.find(wi => wi.maintenanceItem === clp.maintenanceItem)

            if (!workloadlp) {
                clp.isCompleted = true;
                closedWorkload.push(clp);
            }
        });
    }

    let fullWorkload = [];
    if (workload.logpages !== null && workload.logpages !== undefined && workload.logpages.length > 0)
    { 
        // add open log pages
        const open = workload.logpages.filter(lp => lp.discrepancyStatusCode === "O");
        // console.log('open', open);
        fullWorkload = [...validWorkload, ...open, ...closedWorkload];
        // console.log('full workload', fullWorkload);
    }

    let isApu = false;
    if (apuInformation && apuInformation.length > 0 && apuInformation[0].isApuRunning && apuInformation[0].activityDate) {
        const utc = moment().utc().format();
			const curr = moment(utc).utcOffset(offset).format('MM/DD/YYYY HH:mm');
			const activityDate = moment(apuInformation[0].activityDate);
			const currentDate = moment(curr);
			const minutesDiff = Math.floor((moment.duration(currentDate.diff(activityDate))).asMinutes());
			isApu = minutesDiff < 20 && minutesDiff > -5;
    }
    
    return( 
        <div className={selectedFlight !== null ? "workload-container":"workload-container workload-collapse"}>
            {selectedFlight === null ? <div className="workload-content workload-header"></div> : 
            <div>
                <div className="workload-header">
                    <div className="flight-details">
                        <div>
                           <a className="tailnumber" rel='noopener noreferrer' target='_blank' href={encodeURI(`${config.URL_AMT_BASE}/webtech?aircraftNumber=${selectedFlight.tailNumber}`)}>
                               {selectedFlight.tailNumber}
                           </a>
                        </div>
                        <div className="fleet">
                            {`${selectedFlight.fleet}-${selectedFlight.subfleet}`}
                        </div>
                    </div>
                    <div className="icons-container">
                        <div className="icons">                           
                            {selectedFlight.nextIsStar === '0' ? null : <Icon variant='star-icon' primaryColor='#fff' className="icon" />}
                            {selectedFlight.isRon ? <Icon variant='ron-icon' primaryColor='#fff' className='icon' /> : null }
                            {selectedFlight.nextIsEtops ? <Icon variant='etops-icon' primaryColor='#024' className="icon" /> : null}
                            {
                                selectedFlight.mrd && selectedFlight.mrd.displayStatus === 'MFDI' ?
                                    <IssuedMfd className='icon icon-mrd' /> : 
                                selectedFlight.mrd && selectedFlight.mrd.displayStatus === 'MFDARR' ? 
                                    <ArrangedMfd className='icon icon-mrd' /> : 
                                selectedFlight.mrd && selectedFlight.mrd.displayStatus === 'MFDAPP' ? 
                                    <ApprovedMfd className='icon icon-mrd' /> :
                                selectedFlight.mrd === null || !selectedFlight.mrd.hasMrd ? <BrokenMRDIcon className="icon icon-mrd" /> : (selectedFlight.mrd.hasMrd ? <Icon variant='issued-mrd-icon' width='50px' className="icon icon-mrd-issued"/> : null)}                    
                            <a target='_blank' rel='noopener noreferrer' href={encodeURI(`${config.URL_AMT_BASE}/mx/my-work/Workload/WorkloadDetail?${myWork}`)}>
                            {selectedFlight !== null ? (selectedFlight.workloadWrench === 'red-wrench' ? <RedWrench /> : selectedFlight.workloadWrench === 'green-wrench' ? <GreenWrench /> : <GreyWrench />) : <GreyWrench />}
                            </a>
                            {selectedFlight.inspectionRequired ? <Icon variant='inspection-icon' className="icon" /> : null}
                            {selectedFlight.nextIsPriority ? <div className='priority'>P</div> : null}
                            { 
                                config.DISABLE_AC_MOVE ? null :
                                <Icon variant='location-icon' width='9px' height='15px' primaryColor='#fff' className="icon icon-location workload-location" onClick={handleLocationClick.bind(this, selectedFlight)} /> 
                            }
                        </div>
                        <div className="route-info">
                            <div className="icon">
                                <ArrIcon />
                            </div>
                            <div className="routes">
                                <div className="in-route">
                                    <div className="station-gate-info">{selectedFlight.departureCity} / {selectedFlight.arrivalGate}</div>
                                    <div className="time">{moment(selectedFlight.actualInLocal).format('MM/DD/YY HH:mm')}</div>
                                </div>
                                <div className="seperator"></div>
                                <div className="out-route">
                                    {
                                        selectedFlight.nextArrivalCity === "" && 
                                        selectedFlight.nextDepartureGate === "" && 
                                        (selectedFlight.nextEstimateDepartureLocal === null || selectedFlight.nextEstimateDepartureLocal === "")
                                        ?
                                            <div className="no-route-info">
                                                N/A
                                            </div>                                            
                                        :
                                            <div>
                                                <div className="station-gate-info">
                                                    {`${selectedFlight.nextArrivalCity === "" ? '-' : selectedFlight.nextArrivalCity} / ${selectedFlight.nextDepartureGate === "" ? '-' : selectedFlight.nextDepartureGate}`} 
                                                </div>
                                                <div className="time">
                                                    {selectedFlight.nextEstimateDepartureLocal !== null && selectedFlight.nextEstimateDepartureLocal !== "" ? moment(selectedFlight.nextEstimateDepartureLocal).format('MM/DD/YY HH:mm') : "-"}  
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>                            
                            <div className="icon">
                                <DepIcon />
                            </div>
                        </div>
                        <div className="workload-close-container">
                            <div className="menu-wrap" onClick={handleCloseWorkload}>
                                <input type="checkbox" className="toggler-x" />
                                <div className="hamburger"><div></div></div>
                            </div>
                        </div>                        
                    </div>
                </div>
                
                <div className={apuInformation.length > 0 && apuInformation[0].isApuRunning ? 'workload-content shrink' : 'workload-content'}>
                {
                    loading ? <Spinner />
                    :
                    fullWorkload.length === 0 && !loading ? 
                        <div className='workload-item'>
                            <div className="workload">
                                <div className="workload-number"></div>
                                <div></div>
                            </div>
                            <div className="workload-details">
                                <div>currently no workload</div>
                            </div>
                        </div> :
                    fullWorkload.map(w => {
                        const positionCode = w.positionCode !== null && w.positionCode !== undefined ? w.positionCode.replace(/ /g,'').replace(/(X)/g, '') : null;
                        return(
                            <div key={w.maintenanceItem} className={w.discrepancyStatusCode === 'O' ? 'workload-item' : w.isCompleted ? 'workload-item workload-item-complete ' : 'workload-item'} >
                                <div className="workload">
                                    <div className="workload-number">{w.maintenanceItemFormatted}</div>
                                    <div>{w.discrepancyStatusAndType === null || w.discrepancyStatusAndType === undefined ? positionCode : w.discrepancyStatusAndType}</div>
                                    {w.subStation ? <div className='sub-station-ind'>{w.subStation}</div> : <div className='empty-ind'></div>}
                                </div>
                                <div className="workload-details">
                                    <div>{w.jobName ? w.jobName : w.maintenanceDescription}</div>
                                </div>
                            </div>
                        );
                    })
                }
                </div>
            </div>
            }
        </div>
    )
}

export default Workload;