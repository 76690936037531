import React, {useContext, useState, useRef} from 'react';

import { FlifoContext } from '../../contexts/FlifoContext';
import { StationContext } from '../../contexts/StationContext';
import { SlideoutContext } from '../Slideout/SlideoutContext';
import Icon from '../Icon/Icon';

const Search = () => {
    const { updateWorkArea, stationProfile } = useContext(StationContext);
    const { handleSlideout } = useContext(SlideoutContext);
    const { flifo, unknownFlifo } = useContext(FlifoContext);
    const [tailNumber, setTailNumber] = useState("");
    const tailRef = useRef("");

    const _handleKeyDown = (e) => {   
         setTailNumber(e.target.value);
        if (e.key === 'Enter') {
            _handleSearch();            
        }        
      }     
    const _handleSearch = () => { 
        const reg = /^[0-9\b]+$/;
        const isTail = reg.test(tailNumber);
        if (isTail) {

                let tail = flifo ? flifo.find(f => f.tailNumber === tailNumber) : null;
                if (tail) {
                    // console.log('tail searched', tail);
                    let acLocation = tail.tows !== null ? tail.tows[0].location : tail.location;
                    if (acLocation !== null && acLocation.lat !== "" && acLocation.lng !== "") {
                        updateWorkArea(acLocation, 21, tail);
                        // handleSlideout('aircrafts');
                    }          
                }
                else {
                    tail = unknownFlifo ? unknownFlifo.find(f => f.tailNumber === tailNumber) : null;
                    if (tail) handleSlideout('aircrafts', true);
                }
        }
        else {
            if(stationProfile !== null && stationProfile.locations !== null && stationProfile.locations.length > 0)
            {
                let location = stationProfile.locations.find(l => l.locationName === tailNumber.toUpperCase())
                if(location !== undefined && location !== null && location.lat !== "" && location.lng !== "" )
                {                
                    updateWorkArea(location, 21);                
                }
            }
            
        }
     }

    return (        
            <div>
                <div className="search-container">
                    <Icon variant='search-icon' width='35px' height='15px' className="icon search-icon" />
                    <input ref={tailRef} className="search-input" type="text" value={tailNumber} onChange={_handleKeyDown} onKeyDown={_handleKeyDown} placeholder="Search for a flight" ></input>
                </div>
            </div>
    )
}

export default Search;