import React, {useContext} from 'react';
import {StationContext} from '../../contexts/StationContext';
import OrdStation from '../../assets/images/ORD-station.png';

const Dashboard = () => {
    const {isDashboardVisible, setIsDashboardVisible,loadStationProfile } = useContext(StationContext);
    const HandleOverlayClick = (elem) => {
        if(elem.target.id !== null && (elem.target.id === "overlay" || elem.target.id === "cancel")){            
            //setIsDashboardVisible(false);
        }
    }
    const HandleStationClick = (station) => {
        console.log(station);
        loadStationProfile(station); 
        setIsDashboardVisible(false);
    }
    return (<div id="overlay1" className={isDashboardVisible ? "open" : "close"} onClick={HandleOverlayClick.bind(this)}>
        {isDashboardVisible ? <div>           
            <div className="dashboard">
                <div className="station-widget widget-anim-SFO" onClick={HandleStationClick.bind(this, 'SFO')}>
                    <div className="background-map">
                        <img src={OrdStation} className="station-image"/>
                    </div>
                    <div className="widget">
                        <span className="widget-title animate-pop-in">SFO</span>
                    </div>
                </div>
                <div className="station-widget widget-anim-LAX" onClick={HandleStationClick.bind(this, 'DEN')}>
                    <div className="widget">
                        <span className="widget-title animate-pop-in">DEN</span>
                    </div>
                </div>
                <div className="station-widget widget-anim-DEN" onClick={HandleStationClick.bind(this, 'ORD')}>
                    <div className="widget">
                        <span className="widget-title animate-pop-in">ORD</span>
                    </div>
                </div>
                <div className="station-widget widget-anim-EWR" onClick={HandleStationClick.bind(this, 'EWR')}>
                    <div className="widget">
                        <span className="widget-title animate-pop-in">EWR</span>
                    </div>
                </div>                
            </div>
            <div className="dashboard">
                <div className="station-widget widget-anim-SFO" onClick={HandleStationClick.bind(this, 'LAX')}>
                    <div className="background-map">
                        <img src={OrdStation} className="station-image"/>
                    </div>
                    <div className="widget2">
                        <span className="widget-title animate-pop-in">LAX</span>
                    </div>
                    <div className="details-container">
                        <div className="details">
                            <div className="stats">
                                <div className="animate-flyin-left">OOS</div>
                                <div className="animate-flyin-right">02</div>                            
                            </div>
                        </div>
                        <div className="details">
                            <div className="stats">
                                <div className="animate-flyin-left5">On Ground</div>
                                <div className="animate-flyin-right5">32</div>                            
                            </div>
                        </div>
                    </div>                    
                </div>
                <div className="station-widget widget-anim-LAX" onClick={HandleStationClick.bind(this, 'IAH')}>
                    <div className="background-map">
                        <img src={OrdStation} className="station-image"/>
                    </div>
                    <div className="widget1">
                        <span className="widget-title animate-pop-in">IAH</span>
                    </div>  
                    <div className="details-container">
                        <div className="details">
                            <div className="stats">
                                <div className="animate-flyin-left">OOS</div>
                                <div className="animate-flyin-right">01</div>                            
                            </div>
                        </div>
                        <div className="details">
                            <div className="stats">
                                <div className="animate-flyin-left5">On Ground</div>
                                <div className="animate-flyin-right5">35</div>                            
                            </div>
                        </div>
                    </div> 
                </div>
                <div className="station-widget widget-anim-EWR" onClick={HandleStationClick.bind(this, 'IAD')}>
                    <div className="widget1">
                        <span className="widget-title animate-pop-in">IAD</span>
                    </div>
                    <div className="details-container">
                        <div className="details">
                            <div className="stats">
                                <div className="animate-flyin-left">OOS</div>
                                <div className="animate-flyin-right">00</div>                            
                            </div>
                        </div>
                        <div className="details">
                            <div className="stats">
                                <div className="animate-flyin-left5">On Ground</div>
                                <div className="animate-flyin-right5">12</div>                            
                            </div>
                        </div>
                    </div> 
                </div> 
                <div className="station-widget widget-anim-EWR" onClick={HandleStationClick.bind(this, '')}>
                    <div className="widget1">
                        <span className="widget-title animate-pop-in"></span>
                    </div>
                </div>    
            </div>            
            </div>
            /* <div className="dashboard">
                <div className="station-widget1"></div>
                <div className="station-widget1"></div>
                <div className="station-widget1"></div>
                <div className="station-widget1"></div>
                <div className="station-widget1"></div>
                <div className="station-widget1"></div>
                <div className="station-widget1"></div>
            </div> */
            : null}
    </div>)
}
export default Dashboard;