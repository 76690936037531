import developmentOpen from './development-open';
import developmentSandbox from './development-sandbox';
import development from './development';
import local from './local';
import productionOpen from './production-open';
import productionSandbox from './production-sandbox';
import production from './production';
import qaOpen from './qa-open';
import qaSandbox from './qa-sandbox';
import qa from './qa';
import stg from './stg';

const CONFIG = {
    'development-open': developmentOpen,
    'development-sandbox': developmentSandbox,
    development,
    dev: development,
    local,
    'production-open': productionOpen,
    'production-sandbox': productionSandbox,
    production,
    'qa-open': qaOpen,
    'qa-sandbox': qaSandbox,
    qa,
    stg,
};

export const isAWSEnvironment = () => {
    return window.location.host.indexOf('.ual.') >= 0;
}

export const getEnv = () => {
    const env = window.location.hostname.match(/dev|qa|stg/);
    if (env) {
        return `${env[0]}`;
    }
    
    return 'production';
};

export const getConfig = () => {
    if (isAWSEnvironment()) {
        return CONFIG[getEnv()];
    }

    return CONFIG[process.env.REACT_APP_ENVIRONMENT || 'local'];
};