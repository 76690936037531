import React, { createContext, useState } from 'react';
import axios from 'axios';

import { getConfig } from '../config';

export const StationContext = createContext();
const StationContextProvider = (props) => {
	const [stationProfile, setStationProfile] = useState(null);
	const [isDashboardVisible, setIsDashboardVisible] = useState(false);
	const [workArea, setWorkArea] = useState(null);
	const config = getConfig();

	const updateWorkArea = (workarea, zoom, aircraft = null) => {
		if (workarea) setWorkArea({ lat: parseFloat(workarea.lat), lng: parseFloat(workarea.lng), zoom: zoom, aircraft: aircraft });
	}

	const axiosGraphQL = axios.create({
		baseURL: `${config.URL_AMT_API_BASE}${config.AMT_GRAPHQL_ENDPT}`,
	});

	const sleep = (time) => {
		return new Promise((resolve) => setTimeout(resolve, time));
	}
	const cycleworkareas = (workareas, timer) => {
		let counter = 1;
		workareas.filter(wa => wa.lat && wa.lng).forEach(wa => {
			timer += 12000;
			sleep(timer).then(
				() => {
					setWorkArea({ lat: parseFloat(wa.lat), lng: parseFloat(wa.lng), zoom: null });
					if (counter === workareas.length) cycleworkareas(workareas, 0);
					else counter++;
				}
			)
		});
	}

	const loadStationProfile = async (station, workarea = null, rotate = false) => {
		let stationProfileQuery = `{
			overlays(station: "`+ station + `") {
				overlayName, 
				overlayDescription, 
				coordinates, 
				station
			},
			workAreas(station: "`+ station + `") {
				workAreaName, 
				workAreaDescription, 
				workAreaTypeName,
				lat, 
				lng
				direction
			},
			locations(station: "`+ station + `") {
				locationName,
				workAreaName,
				workAreaTypeName,
				lat,
				lng,
				direction
			}
			profile {
				userId
				firstName
				lastName
				station
			}
			stations(mapEnabledOnly: true) {
				stationCode
				lat
				lng
				gmtOffset
			}
		}`
		var stationProfileInfo = await axiosGraphQL
			.post('', { query: stationProfileQuery })
			.then(response => {
				if (response.statusText !== "OK" && response.statusText !== "") throw new Error('Failed to get data');
				return response.data.data;
			})
			.catch((err) => {
				console.log(err);
				window.location.href = "/api/Auth/Load";
			});

		let selectedStation = stationProfileInfo.stations !== null ? stationProfileInfo.stations.find(st => st.stationCode === station) : null;
		let isValidStation = false;
		if (selectedStation !== null && selectedStation !== undefined) {
			// set to specific station, work area and zoom level 
			if (station && workarea) {
				const defaultWorkArea = stationProfileInfo.workAreas.find(wa => wa.workAreaName.trim() === workarea.trim());

				setWorkArea({ lat: parseFloat(defaultWorkArea.lat), lng: parseFloat(defaultWorkArea.lng), zoom: null });
				if (rotate) cycleworkareas(stationProfileInfo.workAreas, 0);
			}
			else setWorkArea({ lat: selectedStation.lat, lng: selectedStation.lng });
			isValidStation = true;
		}
		setStationProfile({
			workAreaNames: [...new Set(stationProfileInfo.workAreas.map(wa => wa.workAreaName))],
			workAreaTypes: [...new Set(stationProfileInfo.workAreas.map(wa => wa.workAreaTypeName))],
			workAreas: stationProfileInfo.workAreas,
			locations: stationProfileInfo.locations,
			overlays: stationProfileInfo.overlays !== null && stationProfileInfo.overlays.map(o => { return { overlayname: o.overlayName, boundaries: o.coordinates !== null ? JSON.parse(o.coordinates) : [] } }),
			stations: stationProfileInfo.stations,
			currentStation: station,
			isValidStation: isValidStation
		});

	};
	return (
		<StationContext.Provider value={{ stationProfile, loadStationProfile, isDashboardVisible, setIsDashboardVisible, workArea, updateWorkArea }}>
			{props.children}
		</StationContext.Provider>
	)
}


export default StationContextProvider;