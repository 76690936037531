import PropTypes from 'prop-types';

export const processLocations = (flifo) => {
    const flifoCurrentLocation = [];
    flifo.forEach(f => {
        const unknown = { locationName: null, lat: null, lng: null, direction: null };
        let current = unknown;
        if (f.tows !== null && f.tows !== undefined && f.tows.length > 0) {
            if (new Date(f.actualInLocal) > new Date(f.tows[0].scheduledTowStartTimeLocal) && f.location !== null) {
                current = { locationName: f.location.locationName, lat: f.location.lat, lng: f.location.lng, direction: f.location.direction };
            }
            else {
                if (f.tows[0].location !== null && f.tows[0].location !== undefined) {
                    current = { locationName: f.tows[0].location.locationName, lat: f.tows[0].location.lat, lng: f.tows[0].location.lng, direction: f.tows[0].location.direction };
                }
                else {
                    if (f.location !== null) current = { locationName: f.location.locationName, lat: f.location.lat, lng: f.location.lng, direction: f.location.direction };
                }
            }
        } 
        else current = { locationName: f.location.locationName, lat: f.location.lat, lng: f.location.lng, direction: f.location.direction };
        flifoCurrentLocation.push({flifo: f, location: current});
    });
    return flifoCurrentLocation;
}

export const findLeavingAircrafts = (previous, current) => {
    const leaving = [];
    previous.forEach(c => {
        const stay = current.find(p => p.flifo.tailNumber === c.flifo.tailNumber && 
                                  p.flifo.flightNumber === c.flifo.flightNumber && 
                                  p.location.locationName === c.location.locationName);
        
        if (stay === undefined) leaving.push(c);
    });
    // const replaced = [];
    // leaving.forEach(l => {
    //     const replacelocation = current.find(c => c.location.locationName === l.location.locationName);
    //     if (replacelocation !== undefined) replaced.push(replacelocation);
    // })
    return leaving;
}

export const GetMrdStatus = (mrd) => {
    return mrd === null ? "broken" : (mrd.hasMrd ? "issued" : "broken");                 
}

processLocations.defaultProps = {
    flifo: []
};

processLocations.propTypes = {
    flifo: PropTypes.array
}