import React, { useEffect, useState } from 'react';

const getViewBox = icon => {
  switch (icon) {
    case 'star-icon':
        return '0 0 26 26';
    case 'ron-icon':
        return '0 0 24 24';
    case 'etops-icon':
        return '0 0 40 40';
    case 'broken-mrd-icon': 
        return '0 0 50 40';
    case 'issue-mrd-icon':
        return '0 0 50 40';
    case 'wrench-icon':
        return '0 0 30 30';
    case 'green-wrench-icon':
        return '0 0 26 26';
    case 'inspection-icon':
        return '0 0 27 28';
    case 'location-icon':
        return '0 0 8 14';
    case 'aircraft-icon':
        return '0 0 30 30';
    case 'wrk-wrench-icon':
        return '0 0 40 40';
    case 'search-icon':
        return '0 0 49.45 51.68';
    case 'mrd-icon':
        return '0 0 49 16';
    case 'unrouted-aircraft-icon':
        return '0 0 25 25';
    case 'parked-aircraft-icon':
        return '0 0 24 19'
    case 'mfd-issue-icon':
        return '0 0 65 26'
    case 'mfd-arrange-icon':
        return '0 0 177 109'
    case 'mfd-approve-icon': 
        return '0 0 177 109'
    default:
      return null;
  }
};

/* eslint-disable */

const Icon = props => {
    const [icon, setIcon] = useState(null);

    const fetchIcon = async () => {
        const { default: Component } = await import(`../../assets/images/icons/${props.variant}.js`);
        const width = props.width === undefined ? '20px' : props.width;
        const height = props.height === undefined ? '20px' : props.height;
        setIcon(
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox={getViewBox(props.variant)} width={width} height={height} {...props}>
                <Component {...props} />
            </svg>
        );
    };

    useEffect(() => {
        fetchIcon();
    }, []); 

    return icon;
};

export default Icon;