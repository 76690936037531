import React, { createContext, useState } from 'react';

import { MAP_INIT_STYLES } from '../../utils/constants';
import {Header} from '../Header/Header';
export const MapContext = createContext();

const MapContextProvider = (props) => {
    const [selectedFlight, setSelectedFlight] = useState(null);
    const [map, setMap] = useState(null);
    const [zoomlevel, setZoomlevel] = useState('mega');
	const [zoomlevelLP, setZoomlevelLP] = useState('mega');

	const [home,setHome] = useState('');
	const [station,setStation] = useState('Header');
	const [date,setDate] = useState('');
    const ZoomChanged = (map, zoomLevel) => {
		if(zoomLevel <=4){setZoomlevelLP('lp');}
		if(zoomLevel>=5){setZoomlevelLP('mini');}
		if (zoomLevel <= 16) {setZoomlevel("mini"); return;}
		if (zoomLevel >= 18) {setZoomlevel("mega"); return;}
		setZoomlevel("max");
	}
    const setDashboardMap = () => {
		//to check united logo click whether from Home/Station Page
		if(station === 'Header'){
			setHome('');
		}else{
			setHome('Home');
		}
			const d = new Date();
			const type = ordinal_suffix_of(d.getDate());
			const gmtDate = new Date();
			gmtDate.setTime(gmtDate.getTime() + 6 * 60 * 60 * 1000);
			var date = d.toLocaleString('default', { weekday: 'long' }) + ' ' + d.toLocaleString('default', { month: 'long' })+ ' ' + type + ' | ORD '+d.getHours().toString().padStart(2, "0") +':'+d.getMinutes().toString().padStart(2, "0")+' | GMT ' +gmtDate.getHours().toString().padStart(2, "0") +':'+gmtDate.getMinutes().toString().padStart(2, "0")+'(+6)';
			setDate(date);
        const map = new window.google.maps.Map(document.getElementById('google-map'), {
			zoom: 5,
			streetViewControl: false,
			fullscreenControl: false,
			center: {
				lat: 38.131278,
				lng: -95.5839279
			},
			mapTypeControlOptions: {
				style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
				position: window.google.maps.ControlPosition.BOTTOM_CENTER,
				mapTypeIds: ['roadmap', 'satellite']
			}
		});	
		map.setOptions({styles: MAP_INIT_STYLES['hide']});
		map.addListener('zoom_changed', function(e) {
			ZoomChanged(map, map.getZoom());   

			// if(type === 'Home' && home === 'Home'){
			// 	if(map.getZoom()<=4){
			// 		setZoomlevelLP('lp');
			// 	}else{
			// 		setZoomlevelLP('mini');
			// 	}
			// }else{ 
			// 	ZoomChanged(map, map.getZoom());   
			// }     
		});
		//for Landing Page
		setZoomlevelLP('mini');
		setMap(map);
    }

	  function ordinal_suffix_of(i) {
		var j = i % 10,
			k = i % 100;
		if (j == 1 && k != 11) {
			return i + "st";
		}
		if (j == 2 && k != 12) {
			return i + "nd";
		}
		if (j == 3 && k != 13) {
			return i + "rd";
		}
		return i + "th";
	}
    return(
        <MapContext.Provider value={{ setDashboardMap, selectedFlight, setSelectedFlight, map, setMap, zoomlevel, setZoomlevel, zoomlevelLP, setZoomlevelLP,home,setHome,station,setStation,date,setDate}}>
            {props.children}
        </MapContext.Provider>
    )
}

export default MapContextProvider;