import React, {useContext} from 'react';
import {LocationContext} from '../../contexts/LocationContext';
import LocationOverlay from './LocationOverlay';
import Dashboard from '../Dashboard/Dashboard';
import {StationContext} from '../../contexts/StationContext';

const Overlay = () => {
    const {locationOverlayActive, UpdateLocationOverlay} = useContext(LocationContext);
    const {isDashboardVisible, setIsDashboardVisible} = useContext(StationContext);
    const HandleOverlayClick = (elem) => {
        if(elem.target.id !== null && (elem.target.id === "overlay" || elem.target.id === "cancel")){
            UpdateLocationOverlay(false);
            setIsDashboardVisible(false);
        }
    }
    return (<div id="overlay" className={locationOverlayActive || isDashboardVisible ? "open" : "close"} onClick={HandleOverlayClick.bind(this)}>
        {locationOverlayActive ? <LocationOverlay handleOverlayClick={HandleOverlayClick} />
                                : isDashboardVisible ? <Dashboard /> 
                                : null}
    </div>)
}

export default Overlay