import React, { useState, useContext } from 'react';

import { FlifoContext } from '../../contexts/FlifoContext';
import { SlideoutContext } from './SlideoutContext';

import SlideoutLocations from './SlideoutLocations/SlideoutLocations';
import SlideoutAircrafts from './SlideoutAircrafts';
import SlideoutLegend from './SlideoutLegend';
import LocationIcon from '../../assets/images/location.svg';
import AircraftsIcon from '../../assets/images/aircrafts.svg';
import LegendIcon from '../../assets/images/legend.svg';
import LocationActiveIcon from '../../assets/images/locationactive.svg';
import AircraftsActiveIcon from '../../assets/images/aircraftsactive.svg';
import LegendActiveIcon from '../../assets/images/legendactive.svg';
import SlideoutOOS from './SlideoutOOS';
import OOSActiveIcon from '../../assets/images/OOSActive.svg';
import OOSIcon from '../../assets/images/OOS.svg';
import { StationContext } from '../../contexts/StationContext';


const Slideout = () => {
    const { inFlifo, outFlifo, unknownFlifo } = useContext(FlifoContext);
    const { activebutton, slideclass, handleSlideout } = useContext(SlideoutContext);
    const { stationProfile } = useContext(StationContext);

    return(
        <div>
        { stationProfile !== undefined && stationProfile !== null && stationProfile.isValidStation?
        <div className={"slideout-container "+slideclass }>
            <div className={activebutton === 'oos'? "slideout-content-oos":"slideout-content"}>
                {
                    {
                        'oos' : <SlideoutOOS station={stationProfile !== undefined && stationProfile !== null ? stationProfile.currentStation: ''}/>,
                        'location' : <SlideoutLocations />,
                        'aircrafts': <SlideoutAircrafts inFlifo={inFlifo} outFlifo={outFlifo} unknownFlifo={unknownFlifo} />,
                        'legend': <SlideoutLegend />
                    }[activebutton]
                }
            </div>
            <div>
                {/* <div className={"slideout-buttons "+slideclass} onClick={handleSlideout.bind(this,"oos")}>
                    {activebutton === "oos" ? <OOSActiveIcon /> : <OOSIcon />}   
                </div> */}
                <div className={"slideout-buttons "+slideclass} onClick={handleSlideout.bind(this,"location")}>
                    {activebutton === "location" ? <LocationActiveIcon /> : <LocationIcon />}   
                </div>
                <div className={"slideout-buttons "+slideclass} onClick={handleSlideout.bind(this,"aircrafts")}>
                    {activebutton === "aircrafts" ?<AircraftsActiveIcon /> : <AircraftsIcon /> }
                </div>
                <div className={"slideout-buttons "+slideclass} onClick={handleSlideout.bind(this,"legend")}>
                    {activebutton === "legend" ?<LegendActiveIcon /> : <LegendIcon /> }
                </div>
            </div>
        </div>:null        }
        </div>
    )
}

export default Slideout;