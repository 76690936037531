import config from './development';

const CONFIG = {
    ...config, 
    ENV: 'qa',
    URL_AMT_API_BASE: 'https://terminalmaps.qa.bzq.aws.ual.com/api',
    URL_AMT_BASE: 'https://amts-oqa.ual.com',
    URL_OOS: 'https://techops-oqa.ual.com/MobileResponsiveReport/',
    AMT_API_ENDPTS: {
        SAVE_AIRCRAFT_LOC: '/station/saveaircraftlocation',
        GET_USER_INFO: '/station/getuserinformation'
      },
    DATA_DOG_APPLICATION_ID: '516ff33d-6ffd-4e13-b0fc-29aae99e200f',
    DATA_DOG_CLIENT_TOKEN: 'pubb5309096817fb2fd02ecbbab4d35d8ac'
};

export default CONFIG;