import React, { useEffect, useContext } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import '../assets/styles/css/app.css';
import TerminalMaps from './TerminalMaps/TerminalMaps';
import { StationContext } from '../contexts/StationContext';
import { ProfileContext } from '../contexts/ProfileContext';
import queryString from 'query-string';
import HealthCheck from './HealthCheck';
import { getConfig } from '../config';
import axios from 'axios';
import { datadogRum } from '@datadog/browser-rum';

function App() {
	const { loadStationProfile } = useContext(StationContext);
	const { profile, setProfile } = useContext(ProfileContext);

	useEffect(() => {
		const config = getConfig();

		datadogRum.init({
			applicationId: config.DATA_DOG_APPLICATION_ID,
			clientToken: config.DATA_DOG_CLIENT_TOKEN,
			site: 'datadoghq.com',
			service:'terminal-maps-cloud',
			
			// Specify a version number to identify the deployed version of your application in Datadog 
			// version: '1.0.0',
			sessionSampleRate: 100,
			sessionReplaySampleRate: 20,
			trackUserInteractions: true,
			trackResources: true,
			trackLongTasks: true,
			defaultPrivacyLevel:'mask-user-input'
		});
			
		datadogRum.startSessionReplayRecording();

		if (!window.location.href.toLowerCase().includes('healthcheck')) {			
			const isLocal = window.location.hostname.match(/localhost/);
			var _window$location$href = window.location.href.split('?');

			if (!isLocal && !(_window$location$href != null && _window$location$href != undefined && _window$location$href.length > 1 && _window$location$href[1].indexOf("code=") > -1)) {
				window.location.href = "/api/Auth/Load";
			}
			else {
				if (!isLocal) {
					axios
						.get(config.URL_AMT_API_BASE + config.AMT_API_ENDPTS.GET_USER_INFO)
						.then((user) => setProfile({
							userId: user.userId,
							firstName: user.userFirstName,
							lastName: user.userLastName,
							station: user.userStation
						}))
						.catch((err) => {
							console.log(err);
							window.location.href = "/api/Auth/Load";
						});
				}
				else {
					setProfile({ userId: 'N079848', firstName: 'Nikhil', lastName: 'Bhomi', station: 'HSC' });
				}
			}
		}
	}, []);

	useEffect(() => {
		const currentUrl = window.location.search.toLowerCase();
		const queryStringValues = queryString.parse(currentUrl);
		if ((queryStringValues.station === undefined || queryStringValues.station === "") && profile !== null) {
			loadStationProfile(profile.station);
		}
		else if (queryStringValues.station && queryStringValues.workarea) {
			const rotate = queryStringValues.rotate && queryStringValues.rotate === 'true' ? true : false
			loadStationProfile(queryStringValues.station.toUpperCase(), queryStringValues.workarea.toUpperCase(), rotate);
		}
		else {
			// if station is spefieid in URL, coming from other apps
			if (queryStringValues.station) {
				loadStationProfile(queryStringValues.station.toUpperCase());
			}
		}
	}, [profile]);

	if (!window.location.href.toLowerCase().includes('healthcheck') && !profile) {
		return <div />;
	}

	return (
		<div className="App">
			<BrowserRouter>
				<Routes>
					<Route path="/healthcheck" element={<HealthCheck />} />
					<Route path="/" element={<TerminalMaps />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;