import config from './development';

const CONFIG = {
    ...config, 
    ENV: 'local',
    URL_AMT_API_BASE: 'https://terminalmaps-api.dev.bzq.aws.ual.com/api',
    URL_AMT_BASE: 'https://amts-odev.ual.com',
    AMT_GRAPHQL_ENDPT: '/graphql/',
    AMT_API_ENDPTS: {
      SAVE_AIRCRAFT_LOC: '/station/saveaircraftlocation',
      GET_USER_INFO: '/station/getuserinformation'
    },
    URL_OOS: 'https://devtechops.ual.com/MobileResponsiveReport/',
    DATA_DOG_APPLICATION_ID: '516ff33d-6ffd-4e13-b0fc-29aae99e200f',
    DATA_DOG_CLIENT_TOKEN: 'pubb5309096817fb2fd02ecbbab4d35d8ac'
};

export default CONFIG;