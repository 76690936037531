import config from './development';

const CONFIG = {
    ...config, 
    ENV: 'development',
    URL_AMT_API_BASE: 'https://terminalmaps.dev.bzq.aws.ual.com/api',
    URL_AMT_BASE: 'https://amts-odev.ual.com', 
    DISABLE_AC_MOVE: true,
    URL_OOS: 'https://devtechops.ual.com/MobileResponsiveReport/'
};

export default CONFIG;