import React, { useState, useContext } from 'react';
import UnitedLogo from '../../assets/images/logo-united.svg'

import { MapContext } from '../Map/MapContext';
import { StationContext } from '../../contexts/StationContext';

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);
    const { stationProfile, loadStationProfile } = useContext(StationContext);
    const { setDashboardMap } = useContext(MapContext);
    const { home, setHome } = useContext(MapContext);
    const { station, setStation } = useContext(MapContext);
    const { date } = useContext(MapContext);
    const ismapEnabled = true;

    return (
        <div className="main-header">
            <div className="header">
                <UnitedLogo onClick={() => { setDashboardMap(); setShowMenu(false); loadStationProfile('HSC'); }} className="header-logo" />
                <div className="header-seperator" />
                <div className="header-station">
                    <ul className="menu">
                        <li onClick={() => !ismapEnabled ? setShowMenu(false) && setHome('') : setShowMenu(!showMenu)}>
                            {stationProfile !== undefined && stationProfile !== null ? stationProfile.currentStation : ''}
                            <ul className={ismapEnabled && !showMenu ? 'sub-menu' : 'sub-menu sub-menu-enabled'}>
                                {
                                    stationProfile !== undefined && stationProfile !== null && stationProfile.stations !== null ?
                                        stationProfile.stations.map(s => {
                                            return (
                                                <li key={s.stationCode} onClick={() => { loadStationProfile(s.stationCode); setShowMenu(false); setHome(''); setStation('Station') }}>{s.stationCode}</li>
                                            )
                                        })
                                        : null
                                }
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="header-date">
                {date && stationProfile !== undefined && stationProfile !== null ?
                    date :
                    null}
            </div>
            <div className='header-logout'>
                <a className='header-logout-link' href='api/auth/LogOff'>Logout</a>
            </div>
        </div>
    )
}

export default Header
