import React, { useContext } from 'react';

import FlifoContextProvider from '../../contexts/FlifoContext';
import WorkloadContextProvider from '../../contexts/WorkloadContext';
import MapContextProvider from '../../components/Map/MapContext';
import LocationContextProvider from '../../contexts/LocationContext';
import SlideoutContextProvider from '../../components/Slideout/SlideoutContext';

import {StationContext} from '../../contexts/StationContext';

import Map from '../../components/Map/Map';
import Header from '../../components/Header/Header';
import SlideOut from '../../components/Slideout/Slideout';
import Workload from '../../components/Workload/Workload';
import Overlay from '../../components/Overlays/Overlay';
import Search from '../../components/Overlays/Search';
import StationInfo from '../../components/Overlays/StationInfo/StationInfo';

const TerminalMaps = () => {
    const {stationProfile} = useContext(StationContext);

    return(
        <div>
            { stationProfile && stationProfile.stations ? <StationInfo stationProfile={stationProfile} /> : null }
            <MapContextProvider>
                <Header />
                <WorkloadContextProvider>
                    <FlifoContextProvider>                        
                        <Map />
                        <LocationContextProvider>
                            <SlideoutContextProvider>
                                <SlideOut />
                                <Search />
                            </SlideoutContextProvider>
                            <Overlay />
                            <Workload />
                        </LocationContextProvider>
                    </FlifoContextProvider>                    
                </WorkloadContextProvider>
            </MapContextProvider>
        </div>
    )
}

export default TerminalMaps