import React, { useState, useEffect } from 'react';
import moment from 'moment';

const StationInfo = (props) => {
   const [displayDate, setDisplayDate] = useState(null);
   const getLocalTime = (stationProfile) => {
      const offset = stationProfile.stations.find(s => s.stationCode === stationProfile.currentStation) === undefined ? null : stationProfile.stations.find(s => s.stationCode === stationProfile.currentStation).gmtOffset;
      return offset === null ? null : 'local time: ' + moment(moment().utc().format()).utcOffset(offset).format('MM/DD HH:mm');
   }

   useEffect(() => {      
      setDisplayDate(getLocalTime(props.stationProfile));

      const interval = setInterval(() => { 
         setDisplayDate(getLocalTime(props.stationProfile));
      }, 60000);
      return () => clearInterval(interval)
   }, [props.stationProfile]);

   // const utc = moment().utc().format();
   // const offset = stationProfile === undefined || stationProfile === null || stationProfile.stations === null ? null : stationProfile.stations.find(s => s.stationCode === stationProfile.currentStation) === undefined ? null : stationProfile.stations.find(s => s.stationCode === stationProfile.currentStation).gmtOffset;
   // // console.log('offset time', moment(utc).utcOffset(offset).format());
   // const currentDate = offset === null ? null : 'local time: ' + moment(utc).utcOffset(offset).format('MM/DD HH:mm');
   return (
      <div className='station-info-container'>
         {displayDate}
      </div>
   )
}

export default StationInfo;