import React, {createContext, useState, useContext} from 'react';
import axios from 'axios';

import { getConfig } from '../config';
import { StationContext } from './StationContext';

export const FlifoContext = createContext();

const FlifoContextProvider = (props) => {
    const {stationProfile} = useContext(StationContext);
    const [flifo, setFlifo] = useState([]);
    const [flifoByStation, setFlifoByStation] = useState([]);

    const [outFlifo, setOutFlifo] = useState([]);
    const [inFlifo, setInFlifo] = useState([]);
    const [unknownFlifo, setUnknownFlifo] = useState([]);  
    const [flifoCount, setFlifoCount] = useState([]);

    const logData = (key, value) => {
      let sanitizedValue = value;
      if(value){
        sanitizedValue = JSON.stringify(value);
      }

      console.log(key, sanitizedValue?.replace(/[\n]/g, '\\n')?.replace(/[\r]/g, '\\r'));
    };
    const config = getConfig();
    const axiosGraphQL = axios.create({
        baseURL: `${config.URL_AMT_API_BASE}${config.AMT_GRAPHQL_ENDPT}` //'http://amtapi-dev.ual.com/svc/station-api/graphql/',
    });

    const updateFlifo = async (station) => {
      let oosQuery = `
        {
          outOfServices(station: "`+station+`"){
            tailNumber,
            location
          }
        }`;
        const qData = `
    {
      flights(station: "`+station+`", ongroundOnly: true) {
        tailNumber, 
        flightNumber,
        nextFlightNumber, 
        departureCity,
        arrivalCity,
        scheduleArrivalLocal,
        estimateArrivalLocal,
        nextScheduleDepartureLocal,
        nextEstimateDepartureLocal,
        fleet, 
        subfleet,
        isEtops, 
        isStar,
        nextIsStar,
        nextIsEtops,
        isRon, 
        apu {
          isApuRunning
          activityDate
          apuRuntime
        },
        flightStatus,
        storageStatus,
        parkingDuration,
        flightState,
        workloadWrench,
        estimateDepartureLocal,
        arrivalGate,
        actualInLocal,
        nextDepartureGate,
        nextArrivalCity,
        inspectionRequired,
        licenseNumber,
        isPriority,
        nextIsPriority
        location {
          locationName,
          lat, 
          lng,
          direction
        }
        mrd {
          mrdStatus, 
          hasMrd, 
          displayStatus
        },
        tows(status: "Finished", recentOnly: true){
          towEvent,
          towTo,
          arriveStation,
          scheduledTowStartTimeLocal,
          towFinishTimeLocal, 
          location {
            locationName,
            lat, 
            lng, 
            direction
          }
        }
      }
    }`;

      const [flifo, oos] = await Promise.all([
        axiosGraphQL
          .post('',{query: qData})
          .then(response => {
            if (response.statusText !== "OK" && response.statusText !== "") throw new Error('Failed to get flifo data');
            return response.data.data.flights;
          }).catch((err) => {
            console.log(err);
            window.location.href = "/api/Auth/Load";
          }),
        axiosGraphQL
          .post('',{query: oosQuery})
          .then(response => {
            if (response.statusText !== "OK" && response.statusText !== "") throw new Error('Failed to get oos data');
            return response.data.data.outOfServices;
          }).catch((err) => {
            console.log(err);
            window.location.href = "/api/Auth/Load";
          })
      ]);
      logData('oos', oos);    

        const inFlights = flifo.filter(f => f.flightState === 'IN');
        const outFlights = flifo.filter(f => f.flightState === "OUT");
        const onGround = flifo.filter(f => f.flightState === "DONE");
        
        logData('in', inFlights);
        logData('out', outFlights);
        logData('ground', onGround);
        
        const isValidLocation = (location) => {
          return location !== null && location.lat !== null && location.lng !== null
        }
        const getTimeToCompare = (ac) => {
          return ac.tows !== null ? ac.tows[0].towFinishTimeLocal : ac.actualInLocal
        }

        var unknown = []; var known = [];
        onGround.forEach(ac => {
          //remove tow if invalid
          if (ac.tows === undefined || ac.tows === null || ac.tows.length <= 0 || new Date(ac.actualInLocal) > new Date(ac.tows[0].scheduledTowStartTimeLocal)) ac.tows = null; 
          const outService = oos.find(o => o.tailNumber === ac.tailNumber);
          ac.oos = outService !== undefined ? outService : null;

          if (ac.arrivalGate.toUpperCase() === "HOLD" && !ac.tows) unknown.push(ac);
          else {
            //check if towdata is valid and check for tow info location validity
            if (ac.tows !== null) {
              if (isValidLocation(ac.tows[0].location)) {
                known.push(ac);
              } 
              else unknown.push(ac);
            }
            //if no valid towdata check for ac location validity
            else {
              if (isValidLocation(ac.location)) known.push(ac);
              else unknown.push(ac);
            }
          }
        });

        let knownOrig = [];
        let unknownOrig = [];        
        //loopthrough known flights and check for duplicates
        known.forEach(ac => {          
             if(knownOrig.find(ko => ko.tailNumber === ac.tailNumber) === undefined && 
                unknownOrig.find(uko => uko.tailNumber === ac.tailNumber) === undefined)
             {
              let acLocationName = ac.tows !== null ? ac.tows[0].location.locationName : ac.location.locationName;
              let duplicates = known.filter(f => 
                                          (f.tows !== null && f.tows[0].location.locationName === acLocationName)
                                          ||
                                          (f.tows === null && f.location.locationName === acLocationName));
              if(duplicates !== null && duplicates.length > 0){
                // console.log('duplicates', duplicates);
                duplicates.sort((a, b) => new Date(getTimeToCompare(b) - new Date(getTimeToCompare(b))));
                knownOrig.push(duplicates[0]);
                let unknownDups = [...duplicates];
                unknownDups.splice(1, duplicates.length - 1).forEach(d => {
                  unknownOrig.push(d);
                });                              
              }
              else {
                knownOrig.push(ac);
              }              
          }
        });
        const fullUnknown = [...unknown, ...unknownOrig];
        logData('unknown', fullUnknown);
        logData('known', knownOrig);
        setFlifo(knownOrig);
        setOutFlifo(outFlights);
        setInFlifo(inFlights);
        setUnknownFlifo(fullUnknown);
    }

    const updateFlifoCount = async (stations) => {
      const qData = `
      {
        lpDetails(landingPage:["EWR","DEN","SFO","IAH","IAD","CLE","TPA","LAX","ORD","MCO"]) {
          station,
          oOSNarrowBody,
          oOSWideBody,
          parkedAircraftCount,
          ronAircraftCount,
          starAircraftCount,
          storedAircraftCount,
          totalAircraftCount,
          unroutedAircraftCount,
          mrdCount,
          localDateTime
      }
    }`;
    // await fetch("https://api.openweathermap.org/data/2.5/weather/?lat=41.40723760&lon=-81.8426646&units=metric&APPID=b893ab91f4412117f8cc8e59fd614b50")
    //   .then(res => res.json())
    //   .then(result => {        
    //     console.log(result);
    //   });
    
      var landingPageDetails = await axiosGraphQL
        .post('', { query: qData })
        .then(response => {
          if (response.statusText !== "OK" && response.statusText !== "") throw new Error('Failed to get data');
          return response.data.data.lpDetails;
        }).catch((err) => {
          console.log(err);
          window.location.href = "/api/Auth/Load";
        });
      
      setFlifoCount(landingPageDetails);
    }

    const updateAircraftMove = (tailNumber, location) => {
      const updatedAircraft = flifo.find(f => f.tailNumber === tailNumber);
      const updatedUnknownAircraft = unknownFlifo.find(f => f.tailNumber === tailNumber);
      if (updatedAircraft) {
        if (updatedAircraft.tows !== null && updatedAircraft.tows.location !== null) {
          updatedAircraft.tows[0].location.locationName = location.locationName;
          updatedAircraft.tows[0].location.lat = location.lat;
          updatedAircraft.tows[0].location.lng = location.lng;
          updatedAircraft.tows[0].location.direction = location.direction;
        }
        else {
          updatedAircraft.location.locationName = location.locationName;
          updatedAircraft.location.lat = location.lat;
          updatedAircraft.location.lng = location.lng;
          updatedAircraft.location.direction = location.direction;
        }
      }
      else if (updatedUnknownAircraft) {
        if (updatedUnknownAircraft.tows !== null && updatedUnknownAircraft.tows.location !== null) {
          updatedUnknownAircraft.tows[0].location.locationName = location.locationName;
          updatedUnknownAircraft.tows[0].location.lat = location.lat;
          updatedUnknownAircraft.tows[0].location.lng = location.lng;
          updatedUnknownAircraft.tows[0].location.direction = location.direction;
        }
        else {
          updatedUnknownAircraft.location.locationName = location.locationName;
          updatedUnknownAircraft.location.lat = location.lat;
          updatedUnknownAircraft.location.lng = location.lng;
          updatedUnknownAircraft.location.direction = location.direction;
        }
      }
      const inFlights = flifo.filter(f => f.flightState === 'IN');
      const outFlights = flifo.filter(f => f.flightState === "OUT");
      const doneFlights = flifo.filter(f => f.flightState === "DONE");
      const onGround = [...doneFlights, ...unknownFlifo];
      debugger
      const isValidLocation = (location) => location !== null && location.lat !== null && location.lng !== null;
      const getTimeToCompare = (ac) => ac.tows !== null ? ac.tows[0].towFinishTimeLocal : ac.actualInLocal;

      var unknown = []; var known = [];
      onGround.forEach(ac => {
        //remove tow if invalid
        if (ac.tows === undefined || ac.tows === null || ac.tows.length <= 0 || new Date(ac.actualInLocal) > new Date(ac.tows[0].scheduledTowStartTimeLocal)) ac.tows = null; 
        if (ac.arrivalGate.toUpperCase() === "HOLD" && !ac.tows) unknown.push(ac);
        else {
          //check if towdata is valid and check for tow info location validity
          if (ac.tows !== null) {
            if (isValidLocation(ac.tows[0].location)) known.push(ac);
            else unknown.push(ac);
          }
          //if no valid towdata check for ac location validity
          else {
            if (isValidLocation(ac.location)) known.push(ac);
            else unknown.push(ac);
          }
        }
      });

      let knownOrig = [];
      let unknownOrig = [];        
      //loopthrough known flights and check for duplicates
      known.forEach(ac => {          
            if(knownOrig.find(ko => ko.tailNumber === ac.tailNumber) === undefined && 
              unknownOrig.find(uko => uko.tailNumber === ac.tailNumber) === undefined)
            {
            let acLocationName = ac.tows !== null ? ac.tows[0].location.locationName : ac.location.locationName;
            let duplicates = known.filter(f => 
                                        (f.tows !== null && f.tows[0].location.locationName === acLocationName)
                                        ||
                                        (f.tows === null && f.location.locationName === acLocationName));
            if(duplicates !== null && duplicates.length > 0){
              // console.log('duplicates', duplicates);
              duplicates.sort((a, b) => new Date(getTimeToCompare(b) - new Date(getTimeToCompare(b))));
              knownOrig.push(duplicates[0]);
              let unknownDups = [...duplicates];
              unknownDups.splice(1, duplicates.length - 1).forEach(d => {
                unknownOrig.push(d);
              });                              
            }
            else {
              knownOrig.push(ac);
            }              
        }
      });
      const fullUnknown = [...unknown, ...unknownOrig];
      logData('unknown', fullUnknown);
      logData('known', knownOrig);

      setFlifo(knownOrig);
      setOutFlifo(outFlights);
      setInFlifo(inFlights);
      setUnknownFlifo(fullUnknown);
    }


    const getFlifoByStation = async () => {
      setFlifoByStation([
        {station: 'ORD', lat: 41.9788679, lng: -87.9069950, oos: 2, onGround: 23}, 
        {station: 'EWR',lat: 40.695573, lng: -74.174513, oos: 1, onGround: 31},
        {station: 'IAH',lat: 29.989421, lng: -95.337823, oos: 1, onGround: 31},
        {station: 'SFO',lat: 37.617171, lng: -122.381617, oos: 1, onGround: 31},
        {station: 'DEN',lat: 39.856426, lng: -104.673384, oos: 1, onGround: 31},
        {station: 'LAX',lat: 33.943289, lng: -118.409678, oos: 1, onGround: 31},
        {station: 'BOS',lat: 42.365478, lng: -71.016706, oos: 1, onGround: 31}
      ]);
    }

    return(
                <FlifoContext.Provider value={{flifo, outFlifo, inFlifo, unknownFlifo,flifoCount, updateFlifo, updateFlifoCount, updateAircraftMove, flifoByStation, getFlifoByStation }}>
            {props.children}
        </FlifoContext.Provider>
    )
}

export default FlifoContextProvider

