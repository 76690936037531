import React, {useContext, useState, useEffect} from 'react';
import { StationContext } from '../../../contexts/StationContext';
import { ProfileContext } from '../../../contexts/ProfileContext';
import SlideoutLocationSection from './SlideoutLocationSection';

const SlideoutLocations = () => {
    const {stationProfile, updateWorkArea, loadStationProfile} = useContext(StationContext);
    const { profile } = useContext(ProfileContext);
    const [selectedLocationItem,setLocationItem] = useState("");
    const [selectedLocationType,setlocationType] = useState("Station");

    useEffect(()=> {
        setLocationItem(stationProfile.currentStation);
    },[stationProfile])

    const handleLocationTypeClick = (locationType) => {        
        setlocationType(selectedLocationType === locationType?"":locationType);
    }
    
    const handleLocationItemClick = (locationItem,locationItemDisplay,locationType) => {
        setLocationItem(locationItemDisplay);
        updateWorkArea(locationItem, 18);
        if(locationType === "Station")
        {
            loadStationProfile(locationItemDisplay);
        }
    }
    
    const availablestations = [...new Set(stationProfile.stations.map(s => s.stationCode))];
    return(
         stationProfile !== null ?
        <div className="workarea">
            {
                !availablestations.includes(profile.station) ? 
                <>
                    {
                        stationProfile.workAreas !== null && stationProfile.workAreas.length > 0 ?
                            stationProfile.workAreaTypes.map(wat => {
                                return <SlideoutLocationSection key={wat} locationType={wat} handleLocationTypeClick={handleLocationTypeClick}
                                                                selectedLocationType = {selectedLocationType} locationItems = {stationProfile.workAreas.filter(wa => wa.workAreaTypeName === selectedLocationType)} selectedLocationItem ={selectedLocationItem} handleLocationItemClick={handleLocationItemClick} />                     
                            })
                        : 
                        null 
                    }
                </>
                :
                <>
                {
                    stationProfile.workAreas !== null && stationProfile.workAreas.length > 0 ?
                            stationProfile.workAreaTypes.map(wat => {
                                return <SlideoutLocationSection locationType={wat} handleLocationTypeClick={handleLocationTypeClick}
                                                                selectedLocationType = {selectedLocationType} locationItems = {stationProfile.workAreas.filter(wa => wa.workAreaTypeName === selectedLocationType)} selectedLocationItem ={selectedLocationItem} handleLocationItemClick={handleLocationItemClick} />                     
                            })
                    :
                    null
                }
                </>
            }            
       </div>
        :
        <div>
            No locations found
        </div>
    )
}

export default SlideoutLocations;