import React, {createContext, useState} from 'react';
import axios from 'axios';
import moment from 'moment';

import { getConfig } from '../config';

export const LocationContext = createContext();

const LocationContextProvider = (props) => {
    const [locationOverlayActive, setLocationoverlayActive] = useState(false);
    const [selectedLocUpdateFlight, setSelectedLocUpdateFlight] = useState();
    const [updateLocSpinner, setupdateLocSpinner] = useState(false);
    const config = getConfig();
    const baseURL  = `${config.URL_AMT_API_BASE}${config.AMT_API_ENDPTS.SAVE_AIRCRAFT_LOC}`;      
    const UpdateLocationOverlay = (active) => {
        setLocationoverlayActive(active);
    }
    const updateSelectedLocpUpdateFlight= (flight) =>{
        setSelectedLocUpdateFlight(flight);
    }
    const UpdateLocation = async (flifo, newlocation, profile) => {        
        let updateLocReq = {
            CreatedBy : `${profile.lastName}, ${profile.firstName}`,
            CreatedDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
            TailNumber : flifo.tailNumber,
            SchedArrDate:flifo.scheduleArrivalLocal,
            Station: flifo.arrivalCity,
            FlightNumber:flifo.flightNumber,
            AircraftRegNumber:flifo.licenseNumber,
            TowFrom:flifo.arrivalGate,
            TowTo: newlocation.locationName
            };    
      const resp = await axios
            .post(baseURL,updateLocReq)
            .then(response => {                
                if (response.status !== 200) throw new Error('Failed to get data');
                setLocationoverlayActive(false);
            })
            .catch((err) => {
				console.log(err);
				window.location.href = "/api/Auth/Load";
			});
    }
    return (
        <LocationContext.Provider value= {{locationOverlayActive, UpdateLocationOverlay, UpdateLocation, updateSelectedLocpUpdateFlight, selectedLocUpdateFlight}}>
            {props.children}
        </LocationContext.Provider>
    )

}

export default LocationContextProvider;

