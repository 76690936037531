import React, { useContext } from 'react';
import Icon from '../Icon/Icon';
import { getConfig } from '../../config';

import {WorkloadContext} from '../../contexts/WorkloadContext';
import {MapContext} from '../../components/Map/MapContext';
import {LocationContext} from '../../contexts/LocationContext';

const SlideoutAircrafts = (props) => {
    const { setSelectedFlight, selectedFlight } = useContext(MapContext);    
    const { getWorkload } = useContext(WorkloadContext);
    const {UpdateLocationOverlay, updateSelectedLocpUpdateFlight} = useContext(LocationContext);
    const config = getConfig();
    const HandleTailClick = (flight) => {
        getWorkload(flight.tailNumber, flight.flightNumber, flight.nextFlightNumber, flight.arrivalCity, flight.scheduleArrivalLocal, flight.estimateArrivalLocal, flight.isRon, flight.nextScheduleDepartureLocal, flight.nextEstimateDepartureLocal);
        setSelectedFlight(flight);
    }
    const HandleLocationClick = (flight) => {
        UpdateLocationOverlay(true);
        updateSelectedLocpUpdateFlight(flight);
    }
    return(
        <div>
            <div>
                <div className="aircraft-header">
                    ON
                </div>
                <div className="aircraft-item-container">
                    {
                        props.inFlifo.map(f => {
                            return(
                                <div key={f.tailNumber} className="aircraft-item">
                                    <div className={selectedFlight !== null && f.tailNumber === selectedFlight.tailNumber? "tailnumber-container clickable active" :"tailnumber-container clickable"} onClick={HandleTailClick.bind(this,f)}>
                                        <span className="tailnumber">{f.tailNumber}</span>
                                    </div>
                                    <div className="gate">
                                        <span>{f.arrivalGate}</span>
                                    </div>
                                    <div className="location-pin">
                                        {/* <Icon variant='location-icon' width='8px' height='14px' className="icon icon-location" onClick={HandleLocationClick.bind(this,f)} />  */}
                                    </div>
                                    <div className="workload">
                                    {f.workloadWrench === 'red-wrench' ? <Icon variant='wrk-wrench-icon' primaryColor='#CD202C' secondaryColor='#E6EAED' className="icon" /> : f.workloadWrench === 'green-wrench' ? <Icon variant='wrk-wrench-icon' primaryColor='#002244' secondaryColor='#53C159' className="icon" /> : <Icon variant='wrk-wrench-icon' primaryColor='#7C848A' secondaryColor='#E6EAED' className="icon" />}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div>
                <div className="aircraft-header">
                    OUT
                </div>
                <div className="aircraft-item-container">
                    {
                        props.outFlifo.map(f => {
                            return(
                                <div key={f.tailNumber} className="aircraft-item">
                                    <div className="tailnumber-container">
                                        <span className="tailnumber">{f.tailNumber}</span>
                                    </div>
                                    <div className="departing-to">
                                        <span>{f.nextArrivalCity}</span>
                                    </div>
                                    <div className="etops">
                                        <span>{f.nextIsEtops ? <Icon variant='etops-icon' primaryColor='#024' className="icon" /> : null}</span>
                                    </div>
                                    <div className="gate">
                                        <span>{f.nextDepartureGate}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div>
                <div className="aircraft-header">
                    HOLD/Others
                </div>
                <div className="aircraft-item-container">
                    {
                        props.unknownFlifo.map(f => {
                            return(
                                <div key={f.tailNumber} className="aircraft-item">
                                    <div className={(selectedFlight !== null && f.tailNumber === selectedFlight.tailNumber? "tailnumber-container clickable active" :"tailnumber-container clickable") + (selectedFlight !== null && f.tailNumber === selectedFlight.tailNumber && selectedFlight.oos !== null ? " oos":"")} onClick={HandleTailClick.bind(this,f)}>
                                        <span className="tailnumber">{f.tailNumber}</span>
                                    </div>
                                    <div className="gate">
                                        {
                                            f.tows !== null ?
                                            f.tows[0].towTo :
                                            f.arrivalGate
                                        }
                                    </div>
                                    <div className="location-pin">
                                        {
                                            config.DISABLE_AC_MOVE ? null :
                                            <Icon variant='location-icon' width='8px' height='14px' className="icon icon-location" onClick={HandleLocationClick.bind(this,f)} /> 
                                        }
                                    </div>
                                    <div className="workload">
                                    {f.workloadWrench === 'red-wrench' ? <Icon variant='wrk-wrench-icon' primaryColor='#CD202C' secondaryColor='#E6EAED' className="icon" /> : f.workloadWrench === 'green-wrench' ? <Icon variant='wrk-wrench-icon' primaryColor='#002244' secondaryColor='#53C159' className="icon" /> : <Icon variant='wrk-wrench-icon' primaryColor='#7C848A' secondaryColor='#E6EAED' className="icon" />}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default SlideoutAircrafts;