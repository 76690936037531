export const MAP_INIT_STYLES = {
    default: null,
    hide: [
        { 
            featureType: 'landscape.man_made', 
            elementType: 'geometry', 
            stylers: [{color: '#b5b0a8'}]
        },
        {
            featureType: 'poi.business',
            stylers: [{visibility: 'off'}]
        },
        {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{visibility: 'off'}]
        },
        {
            featureType: 'landscape',
            elementType: 'labels.text',
            stylers: [{visibility: 'off'}]
        },
        {
            featureType: 'landscape',
            elementType: 'labels.icon',
            stylers: [{visibility: 'off'}]
        }
    ],
    lineStations: ['SFO', 'DEN', 'IAH', 'ORD','TPA'],
    line1Stations:['MCO','IAD']
};