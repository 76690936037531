import React, { createContext, useState } from 'react';
import axios from 'axios';
import { getConfig } from '../config';
export const ProfileContext = createContext();

const ProfileContextProvider = (props) => {
  const [profile, setProfile] = useState(null);
  const config = getConfig();
  const axiosGraphQL = axios.create({
    baseURL: `${config.URL_AMT_API_BASE}${config.AMT_GRAPHQL_ENDPT}`,
  });

  const getUserProfile = async () => {
    if (window.location.href.indexOf('localhost') !== -1) {
      setProfile({ userId: 'N079848', firstName: 'Nikhil', lastName: 'Bhomi', station: 'HSC' });
      return;
    }
    let userProfileQuery = `{          
          profile {
            userId
            firstName
            lastName
            station
          }          
        }`
    var userProfileInfo = await axiosGraphQL
      .post('', { query: userProfileQuery })
      .then(response => {
        if (response.statusText !== "OK" && response.statusText !== "") throw new Error('Failed to get data');
        return response.data.data.profile;
      })
      .catch((err) => {
				console.log(err);
				window.location.href = "/api/Auth/Load";
			});
    if (userProfileInfo.userId !== null && userProfileInfo.userId.toLowerCase() === 'v812898') userProfileInfo.station = 'IAH';
    setProfile(userProfileInfo);
  };
  return (
    <ProfileContext.Provider value={{ profile, getUserProfile, setProfile }}>
      {props.children}
    </ProfileContext.Provider>
  )
}

export default ProfileContextProvider;

