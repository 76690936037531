import React, { createContext, useState } from 'react';

export const SlideoutContext = createContext();

const SlideoutContextProvider = (props) => {
    const [activebutton, setActivebutton] = useState('');
    const [slideclass, setSlideClass] = useState('');

    const handleSlideout = (type, activeOnly = false) => {
        // if (activeOnly) {
        //     setActivebutton(type);
        //     setSlideClass('expand');
        //     return;
        // }

        if (activebutton !== type)
        {
            setActivebutton(type);
            setSlideClass('expand');
        }
        else {
            setActivebutton("");
            setSlideClass('collapse');
        }
    }
    return(
        <SlideoutContext.Provider value={{ activebutton, slideclass, handleSlideout }}>
            {props.children}
        </SlideoutContext.Provider>
    )
}

export default SlideoutContextProvider;