import config from './qa';

const CONFIG = {
    ...config, 
    ENV: 'qa', 
    URL_AMT_API_BASE: 'https://terminalmaps.qa.bzq.aws.ual.com/api',
    URL_AMT_BASE: 'https://amts-oqa.ual.com', 
    DISABLE_AC_MOVE: true,
    URL_OOS: 'https://techops-oqa.ual.com/MobileResponsiveReport/'
};

export default CONFIG;